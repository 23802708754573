import React, { useState } from "react";
import { Link, PageProps } from "gatsby";
import SEO from "../components/seo";
import { products } from "../constants";
import * as styles from "./product.module.scss";
import ArrowCircleLeftIcon from "@mui/icons-material/ArrowCircleLeft";
import AppListItem from "../components/app-list-item";
import QrCodeIcon from "@mui/icons-material/QrCode";
import WebIcon from "@mui/icons-material/Web";
import Dialog from "@mui/material/Dialog";

type PageContext = { id: string };

export default function Product({ pageContext }: PageProps<{}, PageContext>) {
  const product = getProduct(pageContext.id);
  const { setUrl, dialogComponent } = useDialog();
  return (
    <div className={styles.container}>
      {dialogComponent}
      <div className={styles.header}>
        <Link to="/" className={styles.back}>
          <ArrowCircleLeftIcon className="icon" /> <span>返回主页</span>
        </Link>
      </div>
      <div className={styles.app}>
        <div>
          <div className={styles.logo}>
            <AppListItem product={product} index={0} disableLink />
          </div>

          <div className={styles.entries}>
            {product.entries.map(entry => {
              if (entry.type === "weapp")
                return (
                  <a
                    key="weapp"
                    href="#"
                    onClick={() => setUrl(entry.qrcodeUrl)}
                  >
                    <QrCodeIcon className="icon" /> 小程序版
                  </a>
                );

              if (entry.type === "web")
                return (
                  <a href={entry.url} target="_blank" key="web">
                    <WebIcon className="icon" /> 网页版
                  </a>
                );
              return null;
            })}
          </div>
          <div className={styles.features}>
            {product.features.map(feature => (
              <>
                <h3>{feature.name}</h3>
                <p>{feature.description}</p>
              </>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}

export const Head = ({ pageContext }: PageProps<{}, PageContext>) => {
  const product = getProduct(pageContext.id);
  return (
    <SEO
      title={product.meta.title}
      description={product.meta.description}
      keywords={product.meta.keywords}
    />
  );
};

function getProduct(id: string) {
  return products.find(p => p.id === id) ?? products[0];
}

function useDialog() {
  const [url, setUrl] = useState(null);
  const dialogComponent = (
    <Dialog open={!!url} onClose={() => setUrl(null)}>
      <img src={url} />
    </Dialog>
  );
  return {
    dialogComponent,
    setUrl
  };
}
